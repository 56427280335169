import useRestClient from '../hooks/restClient.hook';
import Auth from '../types/auth.type';

export default function useAuthSvc() {
    const restClient = useRestClient();

    async function sendOtp(mobile: string): Promise<null> {
        return restClient.post('/pub/auth/otp', { mobile });
    }

    async function login(auth: Auth): Promise<{ tokenId: string }> {
        return restClient.post('/pub/auth/login', auth);
    }

    return {
        sendOtp,
        login,
    };
}
