import { useContext, useState } from 'react';
import { PollingRes, PollingStatus } from '../types/common/response.type';
import { AppContext } from '../context/app';

interface IUsePoll {
    callback: () => Promise<PollingRes>;
    onSuccess: () => void;
    onFailure: (message?: string) => void;
    interval?: number;
    maxRetries?: number;
}

const { SUCCESS, FAILURE } = PollingStatus;

let poll: NodeJS.Timer;
export const usePolling = (props: IUsePoll) => {
    const [isPolling, setIsPolling] = useState(false);
    const [pollingStatus, setPollingStatus] = useState<PollingStatus>(PollingStatus.NOT_INIT);
    const { callback, onSuccess, onFailure, interval = 5000, maxRetries = 5 } = props;
    const { handleError } = useContext(AppContext);
    const beginPolling = async () => {
        let counter = 0;
        setIsPolling(true);
        poll = setInterval(async () => {
            try {
                const { status, message } = await callback();
                counter += 1;
                if (status === SUCCESS) {
                    onSuccess();
                    clearInterval(poll);
                    setPollingStatus(SUCCESS);
                } else if (status === FAILURE || counter >= maxRetries) {
                    onFailure(message);
                    clearInterval(poll);
                    setPollingStatus(FAILURE);
                }
            } catch (error) {
                clearInterval(poll);
                onFailure();
                handleError(error);
            }
        }, interval);
    };

    const stopPolling = () => {
        clearInterval(poll);
        setIsPolling(false);
        setPollingStatus(PollingStatus.NOT_INIT);
    };

    return {
        beginPolling,
        stopPolling,
        isPolling,
        pollingStatus,
    };
};
