import { Button as AntButton, ButtonProps } from 'antd';

const Button = (props: ButtonProps) => {
    const { size = 'large', type = 'primary' } = props;
    return (
        <AntButton
            {...props}
            size={size}
            type={type}
            className={`${!props.disabled ? 'hover:text-primaryGreen' : ''} shadow-none w-full ${props.className}`}
        >
            {props.children}
        </AntButton>
    );
};

export default Button;
