import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './App';
import { AppStateProvider } from './context/app'
import EventsProvider from './context/events'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ErrorBoundary>
        <BrowserRouter>
            <AppStateProvider>
                <EventsProvider>
                    <App />
                </EventsProvider>
            </AppStateProvider>
        </BrowserRouter>
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
