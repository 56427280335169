import { useLocation } from 'react-router-dom';
import Button from '../components/base/Button';
import { useState } from 'react';
import useOrderSvc from '../services/order.svc';
import { convertObjectToQueryParams, handleFormPost } from '../utils';
import useQuery from '../hooks/useQuery.hook';
import { useContext, useEffect } from 'react'
import { EventsContext } from '../context/events'
import Loader from '../components/Loader';

export default function Failure() {
    const [loading, setLoading] = useState(false);
    const {
        queryParams: { id },
    } = useQuery();
    const { getFailureUrl } = useOrderSvc();

    const { state: routeState } = useLocation();
    const { captureEvent } = useContext(EventsContext);
    const { message } = routeState || {};

    useEffect(() => {
        captureEvent('failure_pv');
    }, []);
    
    const handleContinueShopping = async () => {
        try {
            setLoading(true);
            const { action, merchantParams, method } = await getFailureUrl();
            if (!action) {
                return window.close();
            }
            if (method?.toLowerCase() === 'post') {
                handleFormPost(action, merchantParams);
            } else {
                const params = convertObjectToQueryParams(merchantParams);
                window.location.assign(`${action}?${params}`);
            }
        } catch (error) {
            window.close();
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="flex items-center flex-col gap-[20px]">
            <img className="m-auto" src="/images/txn-failure.svg" />
            <p className="mt-[10px] text-center">{message || 'Something went wrong'}</p>
            {id && (
                <Button className='!w-[180px]' onClick={handleContinueShopping}>
                    Continue Shopping
                </Button>
            )}
        </div>
    );
}
