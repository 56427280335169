declare const window: any;

export const HyperVergeHelper = {
    initHyperVerge(
        updateSelfieUrl: (url: string) => void,
        token: string,
        onCameraPermissionDenied: () => void,
        uploadButtonRef?: React.MutableRefObject<{ upload: { uploader: { fileInput: HTMLInputElement } } } | null>
    ) {
        window.HyperSnapSDK.init(token, window.HyperSnapParams.Region.India);
        window.HyperSnapSDK.startUserSession();
        const callback = (HVError: { errorCode: string }, HVResponse: { imgBase64: string }) => {
            if (HVError) {
                if (HVError.errorCode === '010') {
                    onCameraPermissionDenied();
                    return;
                }
                if (uploadButtonRef?.current) {
                    uploadButtonRef.current.upload?.uploader?.fileInput.click();
                }
            } else if (HVResponse) {
                const { imgBase64 } = HVResponse;
                updateSelfieUrl(imgBase64);
            }
        };
        const hvFaceConfig = new window.HVFaceConfig();
        hvFaceConfig.setLivenessMode(hvFaceConfig.LivenessMode.none);
        hvFaceConfig.setShouldShowInstructionPage(true);
        window.HVFaceModule.start(hvFaceConfig, callback);
    },
};
