import { createContext, useContext, useEffect, useState } from 'react';
import usePlanSvc from '../../services/plan.svc';
import { AppContext } from '../app';
import { Plan } from '../../types/plan.type';
import useQuery from '../../hooks/useQuery.hook';

interface PlanContextType {
    loading: boolean;
    plans: Plan[];
    submitPlan: (planId: string) => void;
}

export const PlanContext = createContext<PlanContextType>({} as PlanContextType);

export const PlanStateProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(false);
    const {
        handleError,
        handleCheckout,
        state: {
            planConfig: { plans, planType },
        },
    } = useContext(AppContext);
    const { selectPlan } = usePlanSvc();

    const submitPlan = async (planId: string) => {
        try {
            setLoading(true);
            const selectedPlan = plans.find((plan) => plan.planId === planId);
            const checkoutState = await selectPlan(selectedPlan as Plan);
            handleCheckout(checkoutState, {
                planId
            });
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // If only one plan is returned, automatically submit it
        if (planType === 'user' && plans.length === 1) {
            submitPlan(plans[0].planId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plans, planType]);

    const values = { loading, plans, submitPlan };

    return <PlanContext.Provider value={values}>{children}</PlanContext.Provider>;
};
