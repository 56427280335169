import { createContext, useContext, useEffect, useState } from 'react';
import { AppContext } from '../app';
import useAutoDebitSvc from '../../services/auto-debit.svc';
import {
    AutoDebitEmandate,
    AutoDebitMethod,
    AutoDebitUpiOption,
    AutoDebitUpiSi,
    EmandateConfig,
    EmandateResType,
    UpiSiListConfig,
    UpiSiRes,
    UpiSiSelectConfig,
} from '../../types/auto-debit.type';
import { formPostInNewWindow, initPaynimo, isMobile } from '../../utils';
import { useLocation } from 'react-router-dom';
import useQuery from '../../hooks/useQuery.hook';
import { usePolling } from '../../hooks/poll.hook';
import { Form, FormInstance } from 'antd';
import { PollingStatus } from '../../types/common/response.type';

interface AutoDebitContextType {
    loading: boolean;
    upiSiListConfig: UpiSiListConfig;
    upiSiSelectConfig: UpiSiSelectConfig;
    updateSelectedUpiIntent: (seamlessUpiIntent: string | null) => void;
    mitcUrl: string;
    submitUpiSiIntent: () => void;
    submitUpiSiNumber: (autoDebitUpiSi: AutoDebitUpiSi) => void;
    activeMethod: AutoDebitMethod | null;
    updateActiveMethod: (method: AutoDebitMethod) => void;
    updateUpiSelectedOption: (option: AutoDebitUpiOption) => void;
    submitEmandate: (emandateData: AutoDebitEmandate, type: string) => void;
    emandateConfig: EmandateConfig;
    updateTermChecked: (checked: boolean) => void;
    termChecked: boolean;
    fetchIfscCodeList: (query: string, bankId: string) => void;
    mitcPopUp: boolean;
    updateMitcPopUp: (status: boolean) => void;
    netbankingForm: FormInstance<AutoDebitEmandate>;
    upiSiForm: FormInstance<AutoDebitUpiSi>;
    isPolling: boolean;
    stopPolling: () => void;
    autoDebitMethodList: AutoDebitMethod;
    pollingStatus: PollingStatus;
}

export const AutoDebitContext = createContext<AutoDebitContextType>({} as AutoDebitContextType);

export const AutoDebitProvider = ({ children }: { children: React.ReactNode }) => {
    const { handleError, moveNext, openNotification } = useContext(AppContext);
    const { getAutoDebit, getMitcUrl, getBankList, getIfscCodeList, updateMitcStatus, submitAutoDebit, pollStatus } =
        useAutoDebitSvc();
    const [loading, setLoading] = useState(false);
    const [upiSiListConfig, setUpiSiListConfig] = useState<UpiSiListConfig>({
        intentList: [],
        domainList: [],
    });
    const [upiSiSelectConfig, setUpiSiSelectConfig] = useState<UpiSiSelectConfig>({
        selectedUpiIntent: null,
        selectedUpiOption: null,
    });
    const [mitcUrl, setMitcUrl] = useState('');
    const [activeMethod, setActiveMethod] = useState<AutoDebitMethod | null>(null);
    const [termChecked, setTermChecked] = useState<boolean>(false);
    const [emandateConfig, setEmandateConfig] = useState<EmandateConfig>({
        bankList: [],
        ifscOptionList: [],
    });
    const [mitcPopUp, setMitcPopUp] = useState<boolean>(false);
    const { state } = useLocation();
    const { fields: autoDebitMethodList } = state || { fields: [] };
    const {
        queryParams: { poll, method, failure_message },
    } = useQuery();

    const [netbankingForm] = Form.useForm<AutoDebitEmandate>();
    const [upiSiForm] = Form.useForm<AutoDebitUpiSi>();
    const autoDebitPollingCount = Number(process.env.REACT_APP_AUTO_DEBIT_POLLING_COUNT || 45);
    const autoDebitPollingInterval = Number(process.env.REACT_APP_AUTO_DEBIT_POLLING_INTERVAL || 4000);
    const autoDebitUpiIntentPollingCount = Number(process.env.REACT_APP_AUTO_DEBIT_UPI_INTENT_POLLING_COUNT || 45);
    const autoDebitUpiIntentPollingInterval = Number(
        process.env.REACT_APP_AUTO_DEBIT_UPI_INTENT_POLLING_INTERVAL || 4000
    );

    const fetchAutoDebitData = async () => {
        try {
            setLoading(true);
            const autoDebitRes = await getAutoDebit();
            const { upiIntents, validUpiDomains } = autoDebitRes ?? {};
            setUpiSiListConfig((prev) => ({
                ...prev,
                intentList: upiIntents,
                domainList: validUpiDomains,
            }));
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBankList = async () => {
        try {
            setLoading(true);
            const bankListRes = await getBankList();
            setEmandateConfig((prev) => ({
                ...prev,
                bankList: bankListRes,
            }));
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMitcUrl = async () => {
        try {
            const { mitcUrl } = await getMitcUrl();
            setMitcUrl(mitcUrl);
        } catch (error) {
            handleError(error);
        }
    };

    const updateSelectedUpiIntent = (seamlessUpiIntent: string | null) => {
        setUpiSiSelectConfig((prev) => ({
            ...prev,
            selectedUpiIntent: seamlessUpiIntent,
        }));
    };

    const submitUpiSiIntent = async () => {
        if (!termChecked) {
            setMitcPopUp(true);
            return;
        }
        const { selectedUpiIntent, selectedUpiOption } = upiSiSelectConfig;
        if (!selectedUpiIntent) return;
        beginPolling();
        try {
            await updateMitcStatus(true);
        } catch (err) {
            handleError(err);
        }
        const autoDebit = {
            autoDebitMethod: selectedUpiOption ?? AutoDebitUpiOption.UPI_INTENT,
            upiIntent: selectedUpiIntent,
        };
        try {
            let res = await submitAutoDebit(autoDebit, AutoDebitUpiOption.UPI_INTENT);
            const a = document.createElement('a');
            res = res as UpiSiRes;
            if (res.redirectUrl) a.href = res.redirectUrl;
            a.click();
        } catch (error) {
            handleError(error);
        }
    };

    const submitUpiSiNumber = async (autoDebitUpiSi: AutoDebitUpiSi) => {
        if (!termChecked) {
            setMitcPopUp(true);
            return;
        }
        const { upiId } = autoDebitUpiSi;
        const autoDebit = {
            autoDebitMethod: AutoDebitUpiOption.UPI_NUMBER,
            vpa: upiId,
        };
        try {
            setLoading(true);
            let res = await submitAutoDebit(autoDebit, AutoDebitUpiOption.UPI_NUMBER);
            res = res as UpiSiRes;
            if (res.params && res.redirectUrl) formPostInNewWindow(res?.redirectUrl, res?.params);
        } catch (error) {
            handleError(error);
            setLoading(false);
        }
    };

    const updateActiveMethod = (method: AutoDebitMethod) => {
        setActiveMethod(method);
    };

    const updateUpiSelectedOption = (option: AutoDebitUpiOption) => {
        setUpiSiSelectConfig((prev) => ({
            ...prev,
            selectedUpiOption: option,
        }));
    };

    const submitEmandate = async (emandateData: AutoDebitEmandate, emandateType: string) => {
        if (!termChecked) {
            setMitcPopUp(true);
            return;
        }
        const { accountNumber, accountHolderName, ifscCode, accountType } = emandateData;
        const autoDebit = {
            autoDebitMethod: AutoDebitMethod.EMANDATE,
            accNumber: accountNumber,
            accType: accountType,
            ifsc: ifscCode,
            name: accountHolderName,
            emandateType: emandateType,
        };
        try {
            setLoading(true);
            let res = await submitAutoDebit(autoDebit, AutoDebitMethod.EMANDATE);
            res = res as EmandateResType;
            initPaynimo(res);
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const fetchIfscCodeList = async (ifscCode: string, bankId: string) => {
        try {
            if (ifscCode?.length < 3 || ifscCode?.length > 10) return;
            const res = await getIfscCodeList(ifscCode, bankId);
            setEmandateConfig((prev) => ({
                ...prev,
                ifscOptionList: res,
            }));
        } catch (error) {
            handleError(error);
        }
    };
    const updateTermChecked = (checked: boolean) => {
        setTermChecked(checked);
    };

    const updateMitcPopUp = (status: boolean) => {
        setMitcPopUp(status);
    };

    const sendMethodName = () => {
        if (method) return method;
        const { selectedUpiOption } = upiSiSelectConfig;
        return selectedUpiOption;
    };

    const isUpiIntentSelected = () => {
        if (isMobile() && activeMethod === AutoDebitMethod.UPI_SI) {
            return upiSiSelectConfig.selectedUpiIntent !== null;
        }
        return false;
    };

    const { beginPolling, stopPolling, isPolling, pollingStatus } = usePolling({
        callback: async () => await pollStatus(sendMethodName()),
        onSuccess: async () => await moveNext(),
        onFailure: async () => await moveNext(),
        maxRetries: isUpiIntentSelected() ? autoDebitUpiIntentPollingCount : autoDebitPollingCount,
        interval: isUpiIntentSelected() ? autoDebitUpiIntentPollingInterval : autoDebitPollingInterval,
    });

    const loadAutoDebitData = async () => {
        if (autoDebitMethodList?.includes(AutoDebitMethod.UPI_SI)) {
            updateActiveMethod(AutoDebitMethod.UPI_SI);
            await fetchAutoDebitData();
        } else {
            updateActiveMethod(AutoDebitMethod.EMANDATE);
        }
        if (autoDebitMethodList?.includes(AutoDebitMethod.EMANDATE)) {
            await fetchBankList();
        }
        await fetchMitcUrl();
    };

    useEffect(() => {
        if (poll) {
            if (failure_message) {
                openNotification(failure_message);
            }
            beginPolling();
        } else {
            loadAutoDebitData();
        }
    }, [poll]);

    const values = {
        loading,
        upiSiListConfig,
        upiSiSelectConfig,
        mitcUrl,
        updateSelectedUpiIntent,
        activeMethod,
        updateActiveMethod,
        submitEmandate,
        mitcPopUp,
        updateTermChecked,
        updateMitcPopUp,
        termChecked,
        emandateConfig,
        submitUpiSiIntent,
        submitUpiSiNumber,
        updateUpiSelectedOption,
        fetchIfscCodeList,
        netbankingForm,
        upiSiForm,
        isPolling,
        stopPolling,
        autoDebitMethodList,
        pollingStatus,
    };
    return <AutoDebitContext.Provider value={values}>{children}</AutoDebitContext.Provider>;
};
