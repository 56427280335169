import { EmandateResType } from '../types/auto-debit.type';
import { IEmiPercentage } from '../types/common/index.type';
import { Plan } from '../types/plan.type';

export const convertObjectToQueryParams = (obj: Record<string, string | boolean | number | undefined>) => {
    const params = [];
    for (const key in obj) {
        if (obj[key]) {
            params.push(`${key}=${encodeURIComponent(obj[key] as string)}`);
        }
    }
    return params.join('&');
};

export const getMinMaxDate = (diff: number) => {
    const dateObj = new Date();
    dateObj.setFullYear(dateObj.getFullYear() - diff);
    const day = `${dateObj.getDate()}`;
    const month = `${dateObj.getMonth() + 1}`;

    const date = [
        `${dateObj.getFullYear()}`,
        month.length === 1 ? `0${month}` : month,
        day.length === 1 ? `0${day}` : day,
    ].join('-');

    return date;
};

export const handleFormPost = (redirectUrl: string, params: Record<string, string> = {}) => {
    let form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', redirectUrl);
    form.setAttribute('target', '_self');
    for (let i in params) {
        if (params.hasOwnProperty(i)) {
            let input = document.createElement('input');
            input.type = 'hidden';
            input.name = i;
            input.value = params[i];
            form.appendChild(input);
        }
    }
    document.body.appendChild(form);
    try {
        form.submit();
    } catch (err) {
        console.log('pop up blocked', err);
    }

    document.body.removeChild(form);
};

export const getRoundedValue = (value: number) => {
    return Math.round(value ? value : 0);
};

function getDaySuffix(day: number) {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}

export const getDateInDayMonthYearFormat = (value: string, suffixCase?: 'upper' | 'lower') => {
    const date = new Date(value);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const daySuffix = getDaySuffix(day);
    if (suffixCase === 'upper') {
        return `${day}<sup>${daySuffix}</sup> ${month}`;
    }
    return `${day}${daySuffix} ${month}`;
};

export const getTenureStringFromPlans = (plans: Plan[]): string => {
    if (!plans || plans.length === 1) return '';
    return plans.reduce(
        (tenureString: string, plan: Plan, index: number) => `${tenureString}${index > 0 ? '/' : ''}${plan.tenure}`,
        ''
    );
};

export const getEMIPercentage = ({ dp, emi, tenure, orderValue }: IEmiPercentage) => {
    const overallAmount = dp + emi * tenure;
    const extraPercentage = ((overallAmount - orderValue) / orderValue) * 100;
    const extraPercentageMonthly = extraPercentage / tenure;
    return extraPercentageMonthly.toFixed(2);
};

export const formPostInNewWindow = (url: string, params: Record<string, string>) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    form.setAttribute('target', '_self');
    for (const i in params) {
        if (params.hasOwnProperty(i)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = i;
            input.value = params[i];
            form.appendChild(input);
        }
    }
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
};

export const initPaynimo = (emandateData: EmandateResType) => {
    if (!document.getElementById('paynimojQuery')) {
        let jqueryScript = document.createElement('script');
        jqueryScript.type = 'text/javascript';
        jqueryScript.id = 'paynimojQuery';
        jqueryScript.src =
            process.env.REACT_APP_PAYNIMO_CHECKOUT_CLIENT_URL ??
            'https://www.paynimo.com/paynimocheckout/client/lib/jquery.min.js';
        document.body.appendChild(jqueryScript);
        jqueryScript.onload = function () {
            var checkoutScript = document.createElement('script');
            checkoutScript.type = 'text/javascript';
            checkoutScript.src =
                process.env.REACT_APP_PAYNIMO_CHECKOUT_SERVER_URL ??
                'https://www.paynimo.com/Paynimocheckout/server/lib/checkout.js';
            document.body.appendChild(checkoutScript);
            checkoutScript.onload = function () {
                startPaynimo(emandateData);
            };
        };
    } else {
        startPaynimo(emandateData);
    }
};

const startPaynimo = (configJson: EmandateResType) => {
    const globalWindow = window as any;
    configJson.consumerData.responseHandler = () => {};
    globalWindow.$.pnCheckout(configJson);
    if (configJson.features.enableNewWindowFlow) {
        globalWindow.$.pnCheckoutShared.openNewWindow();
    }
};
export const getClassName = (
    dynamicClasses: { [key: string]: boolean } | string,
    staticClasses: string = '',
    customClasses: string = ''
): string => {
    let classes = typeof dynamicClasses === 'string' ? dynamicClasses.split(' ') : [];

    if (typeof dynamicClasses === 'object') {
        classes = Object.keys(dynamicClasses).map((key: string) => (dynamicClasses[key] ? key : ''));
    }

    return classes.join(' ') + ' ' + staticClasses + ' ' + customClasses;
};

export const isMobile = () => {
    return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    );
};

export const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
