import { useLocation } from 'react-router-dom';
import useQuery from '../hooks/useQuery.hook';
import { useEffect } from 'react';
import { getRandomInt } from '../utils';
import LoggerService from '../services/logger.svc';
import Failure from './Failure'

const ErrorPage = () => {
    const { state: routeState } = useLocation();
    const { response = {}, message } = routeState || {};
    const {
        queryParams: { id },
    } = useQuery();

    useEffect(() => {
        const num = getRandomInt(1, 10);
        if (num === 1) {
            LoggerService.postLogs(id, message, JSON.stringify(response));
        }
    }, []);

    return (
        <Failure />
    );
};

export default ErrorPage;
