import useRestClient from '../hooks/restClient.hook';
import useQuery from '../hooks/useQuery.hook';
import { PollingRes } from '../types/common/response.type';
import { BankRes, IncomeVerify, SubmitIncomeRes } from '../types/income-verify.type';
import { convertObjectToQueryParams } from '../utils';

export default function useIncomeVerifySvc() {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { id, fetchType } = queryParams;

    async function getBanksList(): Promise<BankRes> {
        return restClient.get(`/pub/bs/banks`);
    }

    async function submitIncomeVerify(incomeVerifyDetails: IncomeVerify): Promise<SubmitIncomeRes> {
        const { fetchType } = incomeVerifyDetails;
        const params = convertObjectToQueryParams({ ...queryParams, fetchType, poll: true });
        const url = `${window.location.origin}/income_verify?${params}`;
        const postData = { ...incomeVerifyDetails, successPath: url, failurePath: url };
        return restClient.post(`/bs/income?id=${id}`, postData);
    }

    async function status(): Promise<PollingRes> {
        const params = convertObjectToQueryParams({ id, fetchType });
        return restClient.get(`/bs/status?${params}`);
    }

    return {
        getBanksList,
        submitIncomeVerify,
        status,
    };
}
