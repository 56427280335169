import { Input as AntInput, InputProps, InputRef } from 'antd';
import { getClassName } from '../../utils';
import { useRef, useState } from 'react';

const Input = (props: InputProps & { toolTipMsg?: string; isRequired?: boolean }) => {
    const [crossClicked, setCrossClicked] = useState(false);
    const inputRef = useRef<InputRef>(null);

    const { type, toolTipMsg, isRequired } = props;
    const isPhone = type === 'phone';
    const inputClasses = getClassName(
        {
            'pl-[60px]': isPhone,
        },
        'peer px-[19px] pb-[12px] h-[56px] pt-[28px] shadow-none text-14',
        props.className ?? ''
    );

    const labelClasses = getClassName(
        {
            'left-[60px]': isPhone,
        },
        'absolute text-12 duration-300 z-10 origin-[0] left-[20px] peer-placeholder-shown:scale-100 translate-y-[-50%] peer-placeholder-shown:top-[50%]  peer-focus:top-[18px] [&:not(peer-placeholder-shown)]:top-[18px] text-lightBody peer-focus:text-lightBody peer-placeholder-shown:text-lightBody pointer-events-none font-medium font-roboto'
    );

    const onInputBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (e.relatedTarget?.className?.includes('tooltip-cross-icon')) {
            setCrossClicked(true);
            inputRef?.current?.focus();
        }
    };

    return (
        <div className="relative flex items-center">
            {isPhone && (
                <div className="absolute z-[999] left-[16px]">
                    <div className="flex items-center gap-[8px]">
                        <p className="text-titleDark">+91</p>
                        <div className="h-[20px] w-[1px] bg-titleDark" />
                    </div>
                </div>
            )}
            <AntInput
                onBlur={(e) => onInputBlur(e)}
                ref={inputRef}
                size="large"
                {...props}
                className={inputClasses}
                placeholder=""
            />
            {!crossClicked && toolTipMsg && (
                <div className="hidden peer-focus:block absolute bottom-[50px] z-[99]">
                    <img src="/images/tool-tip.svg" />
                    <div className="flex items-baseline absolute top-[2px] left-[8px] w-[216px] justify-between">
                        <p className="max-w-[185px] text-[13px] font-robotoRegular text-contentColor">{toolTipMsg}</p>
                        <img
                            tabIndex={-1}
                            id={props.placeholder}
                            src="/images/cross.svg"
                            className="cursor-pointer relative top-[4px] tooltip-cross-icon"
                        />
                    </div>
                </div>
            )}
            <label className={labelClasses}>
                {props.placeholder}
                {isRequired && <span className="text-error">*</span>}
            </label>
        </div>
    );
};

export default Input;
