import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
    name: 'green',
    token: {
        colorPrimary: '#83beca',
        fontFamily: 'Roboto-Regular, sans-serif',
    },
    components: {
        Button: {
            colorPrimary: '#014751',
            colorText: '#014751',
            colorPrimaryHover: '#014751',
            primaryColor: '#AFE137',
            colorPrimaryActive: '#014751',
            fontSize: 16,
            defaultColor: '#b9e058',
            defaultBg: '#1c4650',
            algorithm: true,
            colorTextDisabled: 'white',
            colorBgContainerDisabled: '#90A4A7',
            fontSizeLG: 16,
            borderColorDisabled: 'none',
            controlHeightLG: 35,
        },
        Tag: {
            defaultColor: '#83beca',
            defaultBg: '#fff',
            colorBorder: '#83beca',
        },
        Input: {
            colorPrimary: '#70C1CC',
            fontSizeLG: 14,
            algorithm: true,
            controlHeightLG: 56,
            borderRadiusLG: 4,
            colorError: '#B75E5E',
            hoverBorderColor: '#d9d9d9',
            colorErrorBorderHover: '#B75E5E',
        },
        Form: {
            colorError: '#B75E5E',
            margin: 0,
        },
        Radio: {
            colorPrimary: '#70C1CC',
        },
        Typography: {
            margin: 0,
            marginLG: 0,
        },
        Select: {
            optionActiveBg: '#fff',
            optionSelectedBg: '#fff',
            borderRadiusLG: 4,
            height: 70,
            colorError: '#B75E5E',
        },
        DatePicker: {
            colorError: '#B75E5E',
            controlHeightLG: 56,
        },
    },
    snapmint: {
        colorBg: 'azure',
    },
} as ThemeConfig;

export default theme;
