import { useContext, useEffect, useState } from 'react';
import Button from '../../components/base/Button';
import EmiApproved from '../../components/common/purchase-approved';
import useKycSvc from '../../services/kyc.svc';
import { AppContext } from '../../context/app';
import Loader from '../../components/Loader';
import useQuery from '../../hooks/useQuery.hook';
import Polling from '../../components/common/polling';
import { usePolling } from '../../hooks/poll.hook';
import useCheckoutSvc from '../../services/checkout.svc';
import { useNavigate } from 'react-router-dom';
import { EventsContext } from '../../context/events'

export const Kyc = () => {
    const [loading, setLoading] = useState(false);
    const { submitKyc, pollStatus } = useKycSvc();
    const { handleError, handleCheckout } = useContext(AppContext);
    const { getCheckoutState } = useCheckoutSvc();
    const {
        queryParams: { poll },
    } = useQuery();
    const { captureEvent } = useContext(EventsContext);

    const handleKycSubmit = async () => {
        captureEvent('kyc_submitted');
        try {
            setLoading(true);
            const { redirectUrl } = await submitKyc();
            window.location.replace(redirectUrl);
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const { beginPolling, isPolling, pollingStatus, stopPolling } = usePolling({
        callback: async () => await pollStatus(),
        onSuccess: async () => {
            const checkoutStateDetails = await getCheckoutState();
            handleCheckout(checkoutStateDetails);
        },
        onFailure: async () => {
            const checkoutStateDetails = await getCheckoutState();
            handleCheckout(checkoutStateDetails);
        },
    });

    useEffect(() => {
        let pollingInterval: NodeJS.Timer;
        if (poll) {
            beginPolling();
        } else {
            captureEvent('kyc_pv');
        }
        return () => clearInterval(pollingInterval);
    }, []);

    return (
        <div>
            <EmiApproved />
            {loading && <Loader />}
            {isPolling && (
                <Polling
                    status={pollingStatus}
                    header="Processing KYC..."
                    onRetry={stopPolling}
                    retryButtonText="Retry eKYC"
                    failedMessage="The eKYC process was cancelled by the user"
                />
            )}
            {!loading && (
                <div className="border rounded-lg border-lightGrayOutline border-solid py-20 px-14 bg-white mt-[12px]">
                    <p className="text-14 font-robotoBold text-titleDark">Complete your KYC on Digi Locker App</p>
                    <div className="my-10 bg-divideColor w-full h-[1px]" />
                    <div className="flex justify-between items-center">
                        <img src="/images/snapmint.svg" />
                        <img src="/images/right-arrow.svg" />
                        <div>
                            <img src="/images/digilocker.svg" className="m-auto" />
                            <p className="text-[6px] font-openSansRegular">
                                A Digital Document Wallet from Government of India
                            </p>
                        </div>
                    </div>
                    <Button onClick={handleKycSubmit} className="mt-12">
                        Go To Digilocker
                    </Button>
                </div>
            )}
        </div>
    );
};
