import { createContext, useContext, useEffect, useState } from 'react';
import { AppContext } from '../app';
import useIncomeVerifySvc from '../../services/income-verify.svc';
import { Bank, IncomeVerify } from '../../types/income-verify.type';
import { usePolling } from '../../hooks/poll.hook';
import useCheckoutSvc from '../../services/checkout.svc';
import useQuery from '../../hooks/useQuery.hook';
import { PollingStatus } from '../../types/common/response.type';

interface IncomeVerifyContextType {
    loading: boolean;
    bankList: Array<Bank>;
    submitIncome: (form: IncomeVerify, type: string) => void;
    isPolling: boolean;
    pollingStatus: PollingStatus;
    stopPolling: () => void;
}

export const IncomeVerifyContext = createContext<IncomeVerifyContextType>({} as IncomeVerifyContextType);

export const IncomeVerifyProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(false);
    const [bankList, setBankList] = useState<Array<Bank>>([]);
    const { handleError, handleCheckout } = useContext(AppContext);
    const { getBanksList, submitIncomeVerify, status } = useIncomeVerifySvc();
    const {
        queryParams: { poll },
    } = useQuery();
    const { getCheckoutState } = useCheckoutSvc();

    const { beginPolling, isPolling, pollingStatus, stopPolling } = usePolling({
        callback: async () => await status(),
        onSuccess: async () => {
            const checkoutStateDetails = await getCheckoutState();
            handleCheckout(checkoutStateDetails);
        },
        onFailure: async () => {
            const checkoutStateDetails = await getCheckoutState();
            handleCheckout(checkoutStateDetails);
        },
        interval: parseInt(process.env.REACT_APP_INCOME_VERIFY_POLLING_INTERVAL as string || '5000'),
        maxRetries: parseInt(process.env.REACT_APP_INCOME_VERIFY_POLLING_RETRIES as string || '15'),
    });

    const fetchBanksList = async () => {
        try {
            setLoading(true);
            const bankDetailsRes = await getBanksList();
            const { bankList } = bankDetailsRes ?? {};
            setBankList(bankList);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const submitIncome = async (form: IncomeVerify, fetchType: string) => {
        try {
            setLoading(true);
            if (fetchType) {
                const incomeVerifyRes = await submitIncomeVerify({ ...form, fetchType });
                const { redirectUrl } = incomeVerifyRes ?? {};
                window.location.replace(redirectUrl);
            }
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        if (poll) {
            beginPolling();
        } else {
            fetchBanksList();
        }
    }, []);

    const values = { loading, bankList, submitIncome, isPolling, pollingStatus, stopPolling };
    return <IncomeVerifyContext.Provider value={values}>{children}</IncomeVerifyContext.Provider>;
};
