import { useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from './Snapmint_loader.json';
import { getClassName } from '../../utils'

interface LoaderProps {
    centered?: boolean;
    className?: string;
    subText?: string;
}

const Loader = (props: LoaderProps) => {
    const { centered = true, className = '', subText = '' } = props;
    useEffect(() => {
        const element = document.querySelector('#snap-loader-container-1');
        if (element) {
            element.innerHTML = '';
            lottie.loadAnimation({
                container: element,
                animationData: animationData,
                loop: true,
                autoplay: true,
                renderer: 'svg',
            });
        }
    }, []);
    const containterClass = getClassName({
        'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2': centered,
    })
    const loaderClass = getClassName(className, 'flex items-center w-[100px] h-[100px] m-auto');
    return (
        <div className={containterClass}>
            <div id="snap-loader-container-1" className={loaderClass} />
            {subText && <p className="mt-[10px] text-14 text-center font-robotoBold text-titleDark">{subText}</p>}
        </div>
    );
};

export default Loader;
