import { Navigate } from 'react-router-dom';
import Route from '../types/route.type';
import { AddressStateProvider } from '../context/address';
import { lazy, Suspense } from 'react';
import Home from '../pages/Home';
import Failure from '../pages/Failure';
import Shimmer from '../components/Shimmer';
import { ProfileStateProvider } from '../context/profile';
import { PlanStateProvider } from '../context/plan';
import { IncomeVerifyProvider } from '../context/income-verify';
import { AutoDebitProvider } from '../context/auto-debit';
import Components from '../components/base';
import { Kyc } from '../pages/kyc';
import { OtpStateProvider } from '../context/otp';
import Limits from '../pages/limts';
import { DpProvider } from '../context/dp';
import OrderStatus from '../pages/order-status';
import { SelfieProvider } from '../context/selfie';
import ErrorPage from '../pages/error'

const Otp = lazy(() => import('../pages/otp'));
const Login = lazy(() => import('../pages/login'));
const Profile = lazy(() => import('../pages/profile/index'));
const Address = lazy(() => import('../pages/address'));
const Test = lazy(() => import('../pages/Test'));
const Plan = lazy(() => import('../pages/plan'));
const IncomeVerify = lazy(() => import('../pages/income-verify'));
const AutoDebit = lazy(() => import('../pages/Auto-debit'));
const Dp = lazy(() => import('../pages/dp'));
const Selfie = lazy(() => import('../pages/selfie'));
const searchParams = new URLSearchParams(window.location.search);
const isEligibilityFlow = window.location.pathname.startsWith('/eligibility');

const params = searchParams.toString();

const publicRoutes: Route[] = [
    {
        path: '/otp',
        component: (
            <Suspense fallback={<Shimmer />}>
                <OtpStateProvider>
                    <Otp />
                </OtpStateProvider>
            </Suspense>
        ),
    },
    {
        path: '/login',
        component: (
            <Suspense fallback={<Shimmer />}>
                <Login />
            </Suspense>
        ),
    },
    {
        path: '*',
        component: (
            <Navigate
                to={
                    isEligibilityFlow
                        ? `/login?${params}&redirectUrl=/eligibility&flowType=eligibility`
                        : `/otp?${params}`
                }
                replace
            />
        ),
    },
];

const protectedRoutes: Route[] = [
    {
        path: '/profile',
        component: (
            <Suspense fallback={<Shimmer />}>
                <ProfileStateProvider>
                    <Profile />
                </ProfileStateProvider>
            </Suspense>
        ),
    },
    {
        path: '/address',
        component: (
            <Suspense fallback={<Shimmer />}>
                <AddressStateProvider>
                    <Address />
                </AddressStateProvider>
            </Suspense>
        ),
    },
    {
        path: '/plan',
        component: (
            <Suspense fallback={<Shimmer />}>
                <PlanStateProvider>
                    <Plan />
                </PlanStateProvider>
            </Suspense>
        ),
    },
    {
        path: '/income_verify',
        component: (
            <Suspense fallback={<Shimmer />}>
                <IncomeVerifyProvider>
                    <IncomeVerify />
                </IncomeVerifyProvider>
            </Suspense>
        ),
    },
    {
        path: '/auto-debit',
        component: (
            <Suspense fallback={<Shimmer />}>
                <AutoDebitProvider>
                    <AutoDebit />
                </AutoDebitProvider>
            </Suspense>
        ),
    },
    {
        path: '/',
        component: (
            <Suspense fallback={<Shimmer />}>
                <Home />
            </Suspense>
        ),
    },
    {
        path: '/kyc',
        component: (
            <Suspense fallback={<Shimmer />}>
                <Kyc />
            </Suspense>
        ),
    },
    {
        path: '/selfie',
        component: (
            <Suspense fallback={<Shimmer />}>
                <SelfieProvider>
                    <Selfie />
                </SelfieProvider>
            </Suspense>
        ),
    },
    {
        path: '/dp',
        component: (
            <Suspense fallback={<Shimmer />}>
                <DpProvider>
                    <Dp />
                </DpProvider>
            </Suspense>
        ),
    },
    {
        path: '/order/status',
        component: (
            <Suspense fallback={<Shimmer />}>
                <OrderStatus />
            </Suspense>
        ),
    },
    {
        path: '/eligibility/profile',
        component: (
            <ProfileStateProvider>
                <Suspense fallback={<Shimmer />}>
                    <Profile />
                </Suspense>
            </ProfileStateProvider>
        ),
    },
    {
        path: '/eligibility/kyc',
        component: (
            <Suspense fallback={<Shimmer />}>
                <Kyc />
            </Suspense>
        ),
    },
    {
        path: '/eligibility',
        component: (
            <Suspense fallback={<Shimmer />}>
                <Home />
            </Suspense>
        ),
    },
    {
        path: '/eligibility/limits',
        component: (
            <Suspense fallback={<Shimmer />}>
                <Limits />
            </Suspense>
        ),
    },
    {
        path: '*',
        component: <Navigate to={isEligibilityFlow ? `/eligibility?${params}` : `/?${params}`} replace />,
    },
];

const commonRoutes: Route[] = [
    { path: '/components', component: <Components /> },
    { path: '/failure', component: <Failure /> },
    { path: '/test', component: <Test /> },
    {path: '/error', component: <ErrorPage />}
];

export { publicRoutes, protectedRoutes, commonRoutes };