import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import Routes from './routes';
import Page from './components/layout/Page';
import useQuery from './hooks/useQuery.hook';
import greenTheme from './themes/green.theme';
import blueTheme from './themes/blue.theme';

export default function App() {
    const { queryParams } = useQuery<{ theme: string }>();
    const { theme } = queryParams;
    const themeVersion = theme ?? 'green';

    return (
        <ConfigProvider theme={themeVersion === 'green' ? greenTheme : blueTheme}>
            <StyleProvider hashPriority="high">
                <Page className="page">
                    <Routes />
                </Page>
            </StyleProvider>
        </ConfigProvider>
    );
}
