import useRestClient from '../hooks/restClient.hook';
import useQuery from '../hooks/useQuery.hook';
import { Checkout } from '../types/common/response.type';
import { Profile } from '../types/profile.type';

const useProfileSvc = () => {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const checkoutId = queryParams.id;
    async function addProfile(profile: Profile): Promise<Checkout> {
        return restClient.post(`/profile?id=${checkoutId}`, profile);
    }
    return {
        addProfile,
    };
};

export default useProfileSvc;
