import { useSearchParams } from 'react-router-dom';
import { convertObjectToQueryParams } from '../utils';

interface ParamModel {
    id: string;
    cartId: string;
    cartToken: string;
    merchantId: string;
    poll: boolean;
    errorMsg: string;
    [key: string]: string | boolean;
}

const sanitizeQueryParams = <T>(queryParams: Record<string, string>): ParamModel & T => {
    const sanitizedParams = {
        ...queryParams,
        cartId: queryParams.cartId ?? queryParams.id,
        cartToken: queryParams.token ?? queryParams.cartToken,
        merchantId: queryParams.merchant_id ?? queryParams.merchantId ?? queryParams.mid,
        poll: queryParams.poll === 'true',
        errorMsg: queryParams.errorMsg === 'undefined' ? '' : queryParams.errorMsg,
        id: queryParams.cid,
    } as ParamModel & T;
    return sanitizedParams;
};

const useQuery = <T = Record<string, string>>() => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = Object.fromEntries(searchParams) as T & Record<string, string>;
    const addParams = (obj: Record<string, string>) => {
        setSearchParams(
            {
                ...queryParams,
                ...obj,
            },
            {
                replace: true,
            }
        );
    };

    return {
        queryParams: sanitizeQueryParams<T>(queryParams),
        addParams,
        queryParamsString: convertObjectToQueryParams(queryParams),
    };
};

export default useQuery;
