import { createContext, useContext, useEffect, useState } from 'react';
import { AppContext } from '../app';
import useQuery from '../../hooks/useQuery.hook';
import {
    IDebitCard,
    DpMethod,
    DpUpi,
    DpUpiOption,
    INetBanking,
    NetBankingConfig,
    UpiListConfig,
    UpiSelectConfig,
    UpiQrCodeConfig,
} from '../../types/dp.type';
import useDpSvc from '../../services/dp.svc';
import { usePolling } from '../../hooks/poll.hook';
import { handleFormPost, isMobile } from '../../utils';
import { PollingRes, PollingStatus } from '../../types/common/response.type';
import { Form, FormInstance } from 'antd';
import { useLocation } from 'react-router-dom';
import regexMap from '../../regex';

interface DpContextType {
    loading: boolean;
    upiListConfig: UpiListConfig;
    netBankingConfig: NetBankingConfig;
    activeMethod: DpMethod | null;
    mitcPopUp: boolean;
    updateActiveMethod: (method: DpMethod) => void;
    updateTermChecked: (checked: boolean) => void;
    updateMitcPopUp: (status: boolean) => void;
    termChecked: boolean;
    upiSelectConfig: UpiSelectConfig;
    submitDebitCard: (debitCard: IDebitCard) => void;
    updateUpiSelectedOption: (option: DpUpiOption | null) => void;
    updateSelectedUpiIntent: (seamlessUpiIntent: string | null) => void;
    submitUpiIntent: () => void;
    submitUpiId: (dpUpiId: DpUpi) => void;
    submitNetBanking: (netBanking: INetBanking) => void;
    updateSelectedUpiOption: (option: DpUpiOption | null) => void;
    getStatus: () => Promise<PollingRes>;
    upiQrCodeConfig: UpiQrCodeConfig;
    getUrlForUpiQrCode: () => void;
    updateNetbankingBanks: () => void;
    debitCardForm: FormInstance<IDebitCard>;
    upiForm: FormInstance<DpUpi>;
    netbankingForm: FormInstance<INetBanking>;
    isPolling: boolean;
    stopPolling: () => void;
    dpMethodList: DpMethod;
    mitcUrl: string;
    pollingStatus: PollingStatus;
}

export const DpContext = createContext<DpContextType>({} as DpContextType);

export const DpProvider = ({ children }: { children: React.ReactNode }) => {
    const { handleError, moveNext, openNotification } = useContext(AppContext);
    const { getUpiData, getMitcUrl, getNetBankingBankList, getStatus, submitDp, updateMitcStatus, getUpiQrCode } =
        useDpSvc();
    const [loading, setLoading] = useState(false);
    const [upiListConfig, setUpiListConfig] = useState<UpiListConfig>({
        intentList: [],
        domainList: [],
    });
    const [netBankingConfig, setNetBankingConfig] = useState<NetBankingConfig>({
        bankList: [],
        topBankList: [],
        loading: false,
    });
    const [activeMethod, setActiveMethod] = useState<DpMethod | null>(null);
    const [termChecked, setTermChecked] = useState<boolean>(false);
    const [mitcPopUp, setMitcPopUp] = useState<boolean>(false);
    const [upiSelectConfig, setUpiSelectConfig] = useState<UpiSelectConfig>({
        selectedUpiIntent: null,
        selectedUpiOption: null,
    });
    const [upiQrCodeConfig, setUpiQrCodeConfig] = useState<UpiQrCodeConfig>({
        upiQrCodeUrl: null,
        upiQrCodeLoading: false,
    });
    const {
        queryParams: { poll, failure_message },
    } = useQuery();
    const { state } = useLocation();
    const { fields: dpMethodList } = state || { fields: [] };

    const [debitCardForm] = Form.useForm<IDebitCard>();
    const [upiForm] = Form.useForm<DpUpi>();
    const dpPollingCount = Number(process.env.REACT_APP_DP_POLLING_COUNT || 45);
    const dpPollingInterval = Number(process.env.REACT_APP_DP_POLLING_INTERVAL || 4000);
    const dpPollingUpiIntentCount = Number(process.env.REACT_APP_DP_POLLING_UPI_INTENT_COUNT || 45);
    const dpPollingUpiIntentInterval = Number(process.env.REACT_APP_DP_POLLING_UPI_INTENT_INTERVAL || 4000);
    const [netbankingForm] = Form.useForm<INetBanking>();
    const [mitcUrl, setMitcUrl] = useState('');

    const getUpiIntents = async () => {
        try {
            const dpRes = await getUpiData();
            const { upiIntents, validUpiDomains } = dpRes ?? {};
            setUpiListConfig((prev) => ({
                ...prev,
                intentList: upiIntents,
                domainList: validUpiDomains,
            }));
        } catch (error) {
            handleError(error);
        }
    };

    const getUrlForUpiQrCode = async () => {
        try {
            setUpiQrCodeConfig((prev) => ({
                ...prev,
                upiQrCodeLoading: true,
            }));
            const res = await getUpiQrCode();
            const { upiQrCodeUrl } = res;
            setUpiQrCodeConfig((prev) => ({
                ...prev,
                upiQrCodeUrl: upiQrCodeUrl,
                upiQrCodeLoading: false,
            }));
        } catch (error) {
            handleError(error);
        } finally {
            setUpiQrCodeConfig((prev) => ({
                ...prev,
                upiQrCodeLoading: false,
            }));
        }
    };

    const updateNetbankingBanks = async () => {
        try {
            setNetBankingConfig((prev) => ({
                ...prev,
                loading: true,
            }));
            const bankListRes = await getNetBankingBankList();
            const { netbankingBanks, netbankingOpts } = bankListRes;
            setNetBankingConfig((prev) => ({
                ...prev,
                topBankList: netbankingOpts,
                bankList: netbankingBanks,
            }));
        } catch (error) {
            handleError(error);
        } finally {
            setNetBankingConfig((prev) => ({
                ...prev,
                loading: false,
            }));
        }
    };

    const submitDebitCard = async (debitCard: IDebitCard) => {
        if (!termChecked) {
            setMitcPopUp(true);
            return;
        }
        try {
            setLoading(true);
            await updateMitcStatus(true);
            const { cardName, cvv, digits, validity } = debitCard;
            const digitsOnly = digits?.toString().replace(regexMap.space, '');
            const debitCardReq = {
                paymentMethod: DpMethod.DEBIT_CARD,
                cardName: cardName,
                cvv: cvv,
                digits: digitsOnly,
                validity: validity,
            };
            const res = await submitDp(debitCardReq, DpMethod.DEBIT_CARD);
            if (res.redirectUrl && res.params) {
                handleFormPost(res.redirectUrl, res.params);
            }
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const submitUpiIntent = async () => {
        if (!termChecked) {
            setMitcPopUp(true);
            return;
        }
        const { selectedUpiIntent } = upiSelectConfig;
        if (!selectedUpiIntent) return;
        beginPolling();
        try {
            await updateMitcStatus(true);
            const dpUpiReq = {
                paymentMethod: DpMethod.UPI_INTENT,
                upiIntent: selectedUpiIntent,
            };
            const res = await submitDp(dpUpiReq);
            const a = document.createElement('a');
            a.href = res.redirectUrl;
            a.click();
        } catch (error) {
            handleError(error);
        }
    };

    const submitUpiId = async (dpUpiId: DpUpi) => {
        if (!termChecked) {
            setMitcPopUp(true);
            return;
        }
        try {
            setLoading(true);
            await updateMitcStatus(true);
            const { upiId } = dpUpiId;
            const dpUpiReq = {
                paymentMethod: DpMethod.UPI,
                vpa: upiId,
            };
            const res = await submitDp(dpUpiReq, DpUpiOption.UPI_ID);
            if (res.redirectUrl && res.params) {
                handleFormPost(res.redirectUrl, res.params);
            }
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const submitNetBanking = async (netBanking: INetBanking) => {
        if (!termChecked) {
            setMitcPopUp(true);
            return;
        }
        try {
            setLoading(true);
            await updateMitcStatus(true);
            const { channelCode } = netBanking;
            const netBankingReq = {
                paymentMethod: DpMethod.NET_BANKING,
                bankCode: channelCode,
            };
            const res = await submitDp(netBankingReq, DpMethod.NET_BANKING);
            if (res.redirectUrl && res.params) {
                handleFormPost(res.redirectUrl, res.params);
            }
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const fetchMitcUrl = async () => {
        try {
            const { mitcUrl } = await getMitcUrl();
            setMitcUrl(mitcUrl);
        } catch (error) {
            handleError(error);
        }
    };

    const updateSelectedUpiIntent = (seamlessUpiIntent: string | null) => {
        setUpiSelectConfig((prev) => ({
            ...prev,
            selectedUpiIntent: seamlessUpiIntent,
        }));
    };

    const updateActiveMethod = (method: DpMethod) => {
        setActiveMethod(method);
    };

    const updateUpiSelectedOption = (option: DpUpiOption | null) => {
        setUpiSelectConfig((prev) => ({
            ...prev,
            selectedUpiOption: option,
        }));
    };

    const updateSelectedUpiOption = (option: DpUpiOption | null) => {
        setUpiSelectConfig((prev) => ({
            ...prev,
            selectedUpiOption: option,
        }));
    };

    const updateTermChecked = (checked: boolean) => {
        setTermChecked(checked);
    };

    const updateMitcPopUp = (status: boolean) => {
        setMitcPopUp(status);
    };

    const isUpiIntentSelected = () => {
        if (activeMethod === DpMethod.UPI) {
            return upiSelectConfig.selectedUpiIntent !== null;
        }
        return false;
    };

    const { beginPolling, stopPolling, isPolling, pollingStatus } = usePolling({
        callback: async () => await getStatus(),
        onSuccess: async () => await moveNext(),
        onFailure: async () => await moveNext(),
        interval: isUpiIntentSelected() ? dpPollingUpiIntentInterval : dpPollingInterval,
        maxRetries: isUpiIntentSelected() ? dpPollingUpiIntentCount : dpPollingCount,
    });

    const loadDpData = async () => {
        setLoading(true);
        if (dpMethodList?.includes(DpMethod.UPI)) {
            updateActiveMethod(DpMethod.UPI);
        } else if (dpMethodList?.includes(DpMethod.DEBIT_CARD)) {
            updateActiveMethod(DpMethod.DEBIT_CARD);
        } else {
            updateActiveMethod(DpMethod.NET_BANKING);
        }
        updateMitcPopUp(true);
        if (dpMethodList?.includes(DpMethod.UPI)) {
            await getUpiIntents();
        }
        await fetchMitcUrl();
        setLoading(false);
    };

    useEffect(() => {
        if (poll) {
            if (failure_message) {
                openNotification(failure_message);
            }
            beginPolling();
        } else {
            loadDpData();
        }
    }, [poll]);

    const values = {
        loading,
        upiListConfig,
        netBankingConfig,
        activeMethod,
        mitcPopUp,
        updateActiveMethod,
        updateTermChecked,
        updateMitcPopUp,
        termChecked,
        upiSelectConfig,
        submitDebitCard,
        updateUpiSelectedOption,
        updateSelectedUpiIntent,
        submitUpiIntent,
        submitUpiId,
        submitNetBanking,
        updateSelectedUpiOption,
        getStatus,
        upiQrCodeConfig,
        getUrlForUpiQrCode,
        updateNetbankingBanks,
        debitCardForm,
        upiForm,
        netbankingForm,
        mitcUrl,
        isPolling,
        stopPolling,
        dpMethodList,
        pollingStatus,
    };
    return <DpContext.Provider value={values}>{children}</DpContext.Provider>;
};
