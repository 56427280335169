import { createContext, useContext, useState } from 'react';
import { AppContext } from '../app';
import { Profile } from '../../types/profile.type';
import useProfileSvc from '../../services/profile.svc';
import useEligibilitySvc from '../../services/eligibility.svc';

interface ProfileContextType {
    loading: boolean;
    submitProfile: (profile: Profile) => void;
}

export const ProfileContext = createContext<ProfileContextType>({} as ProfileContextType);

export const ProfileStateProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(false);
    const { handleError, moveNext, isEligibilityFlow } = useContext(AppContext);
    const { addProfile } = useProfileSvc();
    const { checkEligibility } = useEligibilitySvc();

    const submitProfile = async (profile: Profile) => {
        try {
            setLoading(true);
            if (isEligibilityFlow) {
                await checkEligibility(profile);
            } else {
                await addProfile(profile);
            }
            await moveNext();
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <ProfileContext.Provider
            value={{
                submitProfile,
                loading,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};
