const Overlay = ({ children, onClose, heading }: { children: JSX.Element; onClose: () => void; heading: string }) => {
    return (
        <div className="bg-[rgba(0,0,0,0.6)] absolute h-[95svh] z-[1000] top-0 right-0 bottom-0 left-0">
            <div className="rounded-[16px_16px_0px_0px] bg-white shadow-card absolute bottom-0 w-full">
                <div className="flex justify-between py-[20px] px-16">
                    <p className="font-robotoBold text-14 text-primaryGray">{heading}</p>
                    <img className="cursor-pointer" src="/icons/close.svg" alt="close" onClick={onClose} />
                </div>
                <div className="border-[1px] !border-solid border-grayOutline"></div>
                <div className="p-16">{children}</div>
            </div>
        </div>
    );
};

export default Overlay;
