const theme = {
    name: 'blue',
    token: {
        colorPrimary: '#1d46ee',
        borderRadius: 0,
        fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
    snapmint: {
        colorBg: 'ghostwhite',
    },
};

export default theme;
