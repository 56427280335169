import { useEffect, createContext, useContext } from 'react';
import posthog, { CaptureOptions } from 'posthog-js';
import eventData from '../../events/events.json';
import { AppContext } from '../app';

type Event = keyof typeof eventData;
declare global {
    interface Window {
        clarity: (method: string, key?: string, value?: unknown) => void;
    }
}

interface EventsContextType {
    captureEvent: (eventName: Event, payload?: unknown) => void;
}

export const EventsContext = createContext<EventsContextType>({} as EventsContextType);

const EventsProvider = ({ children }: { children: React.ReactNode }) => {
    const {
        state: {
            cart: { id },
        },
    } = useContext(AppContext);
    const initiateEventsTracking = () => {
        posthog.init(process.env.REACT_APP_POST_HOG_KEY as string, {
            api_host: process.env.REACT_APP_POST_HOG_SERVER_URL,
            autocapture: false,
            capture_pageview: false,
            capture_pageleave: false,
        });
        posthog.register({
            cart_id: id,
        });
    };

    const captureEvent = (eventName: Event, payload?: unknown) => {
        const eventDetails = eventData[eventName];
        if (!eventDetails) {
            return;
        }
        const { channels = [] } = eventDetails;
        channels.forEach((channel) => {
            switch (channel) {
                case 'posthog':
                    posthog.capture(eventName, payload as CaptureOptions);
                    break;
                case 'clarity':
                    if (window?.clarity) {
                        window.clarity('set', eventName, JSON.stringify(payload) as CaptureOptions);
                    }
                    break;
                default:
                    break;
            }
        });
    };

    useEffect(() => {
        initiateEventsTracking();
    }, []);

    return (
        <EventsContext.Provider
            value={{
                captureEvent,
            }}
        >
            {children}
        </EventsContext.Provider>
    );
};

export default EventsProvider;
