import { createContext, useContext, useEffect, useRef, useState } from 'react';
import useKycSvc from '../../services/kyc.svc';
import { AppContext } from '../app';
import { HyperVergeHelper } from '../../utils/hyperverge.helper';

interface SelfieContextType {
    loading: boolean;
    selfieConfig: SelfieConfig;
    updateIsModalOpen: (isModalOpen: boolean) => void;
    updateSelfieUrl: (url: string | Blob) => void;
    updateHyperverge: () => void;
    updateToken: () => void;
    submitDocument: () => void;
    uploadButtonRef: React.MutableRefObject<{ upload: { uploader: { fileInput: HTMLInputElement } } } | null>;
    cameraPermissionDenied: boolean;
    setCameraPermissionDenied: (value: boolean) => void;
}

interface SelfieConfig {
    selfieModal: boolean;
    selfie: string | Blob;
    token: string;
}

export const SelfieContext = createContext<SelfieContextType>({} as SelfieContextType);

export const SelfieProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(false);
    const { getJwtToken, uploadFile } = useKycSvc();
    const { handleError, handleCheckout } = useContext(AppContext);
    const [selfieConfig, setSelfieConfig] = useState<SelfieConfig>({
        selfie: '',
        selfieModal: false,
        token: '',
    });
    const uploadButtonRef = useRef(null);
    const [cameraPermissionDenied, setCameraPermissionDenied] = useState(false);
    const updateIsModalOpen = (isModalOpen: boolean) => {
        setSelfieConfig((prev: SelfieConfig) => ({ ...prev, selfieModal: isModalOpen }));
    };

    const updateToken = async (): Promise<void> => {
        try {
            setLoading(true);
            const response = await getJwtToken();
            const jwtData = response.hypervergeToken;
            if (jwtData) {
                setSelfieConfig((prev: SelfieConfig) => ({ ...prev, token: jwtData }));
            }
            setLoading(false);
        } catch (error) {
            handleError(error);
        }
    };

    const onCameraPermissionDenied = () => {
        setCameraPermissionDenied(true);
    };

    const updateHyperverge = async () => {
        const { token } = selfieConfig;
        if (!token) {
            return;
        }
        HyperVergeHelper.initHyperVerge(updateSelfieUrl, token, onCameraPermissionDenied, uploadButtonRef);
    };

    const updateSelfieUrl = (url: string | Blob) => {
        setSelfieConfig((prev: SelfieConfig) => ({ ...prev, selfie: url }));
        updateIsModalOpen(false);
    };

    const submitDocument = async () => {
        try {
            setLoading(true);
            const selfieReq: { [key: string]: string } = {
                docType: 'selfie',
                docCategory: 'selfie',
            };
            const data = new FormData();
            let selfieFile = null;
            if (typeof selfieConfig.selfie === 'string') {
                selfieFile = await fetch(selfieConfig.selfie).then((res) => res.blob());
            } else {
                selfieFile = selfieConfig.selfie;
            }
            data.append('file', selfieFile);
            Object.keys(selfieReq).forEach((key) => {
                data.append(key, selfieReq[key]);
            });
            const checkoutState = await uploadFile(data);
            handleCheckout(checkoutState);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        updateToken();
    }, []);

    const values = {
        loading,
        selfieConfig,
        updateIsModalOpen,
        updateSelfieUrl,
        updateHyperverge,
        updateToken,
        submitDocument,
        uploadButtonRef,
        cameraPermissionDenied,
        setCameraPermissionDenied,
    };
    return <SelfieContext.Provider value={values}>{children}</SelfieContext.Provider>;
};
