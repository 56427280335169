import { useContext, useEffect, useState } from 'react';
import useOrderSvc from '../../services/order.svc';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/app';
import Order from '../../types/order.type';
import { OrderStatus as OrderStatusEnum } from '../../types/common/response.type';
import { handleFormPost } from '../../utils';
import useQuery from '../../hooks/useQuery.hook';
import { EventsContext } from '../../context/events'

const OrderStatus = () => {
    const [orderDetails, setOrderDetails] = useState<Order>({} as Order);
    const { handleError } = useContext(AppContext);
    const navigate = useNavigate();
    const { getOrderStatus, getOrderDetails } = useOrderSvc();
    const { queryParamsString } = useQuery();
    const { captureEvent } = useContext(EventsContext);

    useEffect(() => {
        (async () => {
            const orderDetails = await getOrderDetails();
            setOrderDetails(orderDetails);
        })();
    }, []);

    useEffect(() => {
        if (Object.keys(orderDetails).length > 0) {
            captureEvent('ordersuccess_pv');
            getOrderStatus()
                .then(({ status, message, method, action, merchantParams }) => {
                    if (status === OrderStatusEnum.FAILURE.toLowerCase()) {
                        return navigate(`/failure?${queryParamsString}`, { state: { message } });
                    } else if (status === OrderStatusEnum.SUCCESS.toLowerCase()) {
                        if (method === 'POST') {
                            handleFormPost(action as string, merchantParams);
                        } else {
                            window.location.href = action as string;
                        }
                    } else {
                        throw new Error('Incorrect method recieved', {
                            cause: { method },
                        });
                    }
                })
                .catch((error) => {
                    handleError(error);
                });
        }
    }, [orderDetails]);

    const { orderValue, paymentId, dp } = orderDetails;
    const orderInfo = [
        {
            label: 'Payment id',
            text: paymentId,
            value: paymentId,
        },
        {
            label: 'Order Value',
            text: `₹${orderValue}`,
            value: orderValue,
        },
        {
            label: 'Downpayment Paid',
            text: `₹${dp}`,
            value: dp,
        },
    ].filter(({ value }) => !!value);

    return (
        <div className="relative flex flex-col justify-center items-center gap-[20px]">
            <Loader centered={false} className="w-[120px] h-[120px]" subText="Order Processing..." />
            <p className="text-14 font-robotoRegular text-lightBody text-center">
                Do not close this window <br /> Do not click on the back button
            </p>
            {Object.keys(orderDetails).length > 0 && (
                <>
                    <div className="py-[10px] w-full max-w-[360px] text-12 font-robotoMedium flex flex-col gap-[10px] border border-solid border-divideColor rounded-lg">
                        {orderInfo.map(({ label, text }, i) => {
                            return (
                                <>
                                    <div className="flex justify-between px-[12px]">
                                        <div>{label} : </div>
                                        <div>{text}</div>
                                    </div>
                                    {i < orderInfo.length - 1 && <div className="h-[1px] bg-divideColor w-full" />}
                                </>
                            );
                        })}
                    </div>
                    <div className="max-w-[250px] w-full mx-auto font-robotoRegular text-lightBody text-12 text-center">
                        For any Payment / EMI related queries, please call on 022-48931351
                    </div>
                    <div className='flex items-center gap-[4px]'>
                        <img src='/images/snapmint-green-logo.svg' className='w-[25px]' />
                        <p className='text-16 text-titleDark font-robotoRegular'>Pay EMIs on Snapmint App</p>
                    </div>
                </>
            )}
        </div>
    ); 
        
};

export default OrderStatus;
