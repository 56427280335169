const routeMap = {
    DEFAULT: '/login',
    ADDRESS: '/address',
    PROFILE: '/profile',
    PLAN: '/plan',
    INCOME_VERIFY: '/income_verify',
    KYC: '/kyc',
    SELFIE: '/selfie',
    AUTODEBIT: '/auto-debit',
    kyc_required: 'kyc',
    pan_required: 'pan',
    DP: '/dp',
};

export default routeMap;
