import axios, { AxiosResponse } from 'axios';

export default function useRestClient() {
    const restClient = axios.create({
        baseURL: process.env.NODE_ENV === 'development' ? undefined : process.env.REACT_APP_API_BASE_URL,
    });

    const token = localStorage.getItem('tk');
    if (token) {
        restClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    restClient.interceptors.response.use(
        (res: AxiosResponse) => {
            return res.data;
        },
        (error) => {
            console.log('===> intercept', error);
            throw error;
        }
    );

    return restClient;
}
