import { createContext, useContext, useEffect, useReducer } from 'react';
import { AddressAction, AddressReducer, AddressState, initialAddressState } from '../address/reducer';
import Address from '../../types/address.type';
import useAddressSvc from '../../services/address.svc';
import { AppContext } from '../app'

interface AddressContextType {
    state: AddressState;
    updateLoading: (loading: boolean) => void;
    updateAddressList: (addressList: AddressState['addressList']) => void;
    submitAddress: (address: Address) => void;
    fetchCityFromPinCode: (pincode: string) => Promise<string>;
}

const { UPDATE_LOADING, UPDATE_ADDRESS_LIST } = AddressAction;

export const AddressContext = createContext<AddressContextType>({} as AddressContextType);

export const AddressStateProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(AddressReducer, initialAddressState);
    const { handleError, handleCheckout } = useContext(AppContext);
    const { getAddresses, addAddress, getCity } = useAddressSvc();

    const updateLoading = (loading: boolean) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: { loading },
        });
    };

    const updateAddressList = (addressList: AddressState['addressList']) => {
        dispatch({
            type: UPDATE_ADDRESS_LIST,
            payload: {
                addressList,
            },
        });
    };

    const fetchAddressList = async () => {
        try {
            updateLoading(true);
            const addresses = await getAddresses();
            updateAddressList(addresses);
        } catch (error) {
            handleError(error);
        } finally {
            updateLoading(false);
        }
    };

    const submitAddress = async (address: Address) => {
        try {
            updateLoading(true);
            const checkoutState = await addAddress(address);
            handleCheckout(checkoutState);
        } catch (error) {
            handleError(error);
        } finally {
            updateLoading(false);
        }
    };

    const fetchCityFromPinCode = async (pincode: string) => {
        try {
            const { city } = await getCity(pincode);
            return city as string;
        } catch (error) {
            handleError(error);
            return ''
        }
    };

    useEffect(() => {
        fetchAddressList();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value: AddressContextType = {
        state,
        updateAddressList,
        updateLoading,
        submitAddress,
        fetchCityFromPinCode,
    };

    return (
        <AddressContext.Provider value={value}>
            {children}
        </AddressContext.Provider>
    );
};
