export enum AutoDebitMethod {
    UPI_SI = 'UPI_SI',
    EMANDATE = 'emandate',
}

export enum AutoDebitUpiOption {
    UPI_NUMBER = 'upi_id',
    UPI_INTENT = 'upi_intent',
}
export interface AutoDebitEmandate {
    bankMasterId: number;
    accountNumber: string;
    accountHolderName: string;
    ifscCode: string;
    accountType: string;
    fetchType: string;
}

export interface Banks {
    id: number;
    ifscPrefix: string;
    name: string;
    emandateTypes: string[];
}

export const AccountTypeList = [
    { label: 'Savings', value: 'SB' },
    { label: 'Current', value: 'CA' },
    { label: 'Others', value: 'other' },
];

export interface SeamlessUpiIntent {
    iconUrl: string;
    isAllowed: boolean;
    name: string;
    packageName: string;
    paymentMode: string;
}

export interface AutoDebitDataRes {
    validUpiDomains: string[];
    upiIntents: SeamlessUpiIntent[];
}
export interface AutoDebitReq {
    autoDebitMethod: string;
    vpa?: string;
    upiIntentPaymentMode?: string;
    accNumber?: string;
    accType?: string;
    ifsc?: string;
    name?: string;
}

export interface UpiSiListConfig {
    intentList: SeamlessUpiIntent[];
    domainList: string[];
}

export interface EmandateConfig {
    bankList: Banks[];
    ifscOptionList: string[];
}

export interface UpiSiSelectConfig {
    selectedUpiIntent: string | null;
    selectedUpiOption: AutoDebitUpiOption | null;
}

export interface AutoDebitUpiSi {
    upiId: string;
}

export interface EmandateResType {
    consumerData: responseHandlerType;
    features: enableNewWindowFlowType;
    tarCall: boolean;
}

export interface responseHandlerType {
    responseHandler: () => void;
}

export interface enableNewWindowFlowType {
    enableNewWindowFlow: boolean;
}

export interface UpiSiRes {
    redirectUrl?: string;
    params?: Record<string, string>;
}
