const EmiApproved = () => {
    return (
        <div className="flex gap-[4px] items-center">
            <img src="/images/purchase_approved.svg" className="w-[18px] h-[18px]" />
            <p className="text-titleDark text-13 font-robotoBold">Your EMI purchase is approved</p>
        </div>
    );
};

export default EmiApproved;
