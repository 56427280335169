import useRestClient from '../hooks/restClient.hook';
import useQuery from '../hooks/useQuery.hook';
import { Checkout, PollingRes } from '../types/common/response.type';
import { convertObjectToQueryParams } from '../utils';

const useKycSvc = () => {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { id } = queryParams;

    async function submitKyc(): Promise<{ redirectUrl: string }> {
        const params = convertObjectToQueryParams({ ...queryParams, poll: true });
        const url = `${window.location.origin}/kyc?${params}`;
        return restClient.post(`/kyc/ekyc?id=${id}`, {
            successUrl: url,
            failureUrl: url,
        });
    }

    async function pollStatus(): Promise<PollingRes> {
        return restClient.get(`/kyc/status?id=${id}`);
    }

    async function getJwtToken(): Promise<{ hypervergeToken: string }> {
        return await restClient.get(`/kyc/selfie/token?id=${id}`);
    }

    async function uploadFile(uploadReq: FormData): Promise<Checkout> {
        return await restClient.post(`/kyc/selfie?id=${id}`, uploadReq, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    return {
        submitKyc,
        pollStatus,
        getJwtToken,
        uploadFile,
    };
};

export default useKycSvc;
