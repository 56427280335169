import Axios from 'axios';

const LoggerService = {
    postLogs: (id: string, message: string, response: string) => {
        return Axios.post(`${process.env.REACT_APP_APIS_BASE_URL}/pub/logs?checkoutId=${id}`, {
            level: 'error',
            message,
            payload: response,
            origin: 'ck-3.0',
        });
    },
};

export default LoggerService;
