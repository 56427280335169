import { Checkbox, DatePicker, Form, Radio, Select, Space } from 'antd';
import Address from '../../types/address.type';
import { Profile } from '../../types/profile.type';
import day from 'dayjs';
import Input from './input-field'
import Button from './Button'

const Components = () => {
    const [form] = Form.useForm<Address>();
    const items = ['jack', 'lucy'];

    return (
        <div>
            <Form
                validateTrigger={['onSubmit']}
                layout="vertical"
                form={form}
            >
                <Form.Item<Address>
                    name={'addressLine1'}
                    normalize={(value: string) => value.replace(/\W|_/g, '')}
                    rules={[
                        {
                            required: true,
                            min: 2,
                            message: 'Address is required',
                        },
                    ]}
                >
                    <Input size="large" placeholder="Flat / House no / Building / Company name" />
                </Form.Item>
                <Form.Item<Profile>
                    name={'dob'}
                    rules={[
                        {
                            type: 'object',
                            required: true,
                            message: 'Please Enter Date of Birth',
                        },
                    ]}
                >
                    <DatePicker
                        maxDate={day().subtract(18, 'years')}
                        minDate={day().subtract(100, 'years')}
                    />
                </Form.Item>
                <Checkbox>Simple Checkbox</Checkbox>
                <Form.Item<Profile>
                    name={'gender'}
                    rules={[
                        {
                            required: true,
                            message: 'Please Select a Gender',
                        },
                    ]}
                >
                    <div className="flex gap-4 items-center mt-16">
                        <Radio.Group>
                            <Radio value={'m'}>
                                <div>Male</div>
                            </Radio>
                            <Radio value={'f'}>
                                <div>Female</div>
                            </Radio>
                            <Radio value={'o'}>
                                <div>Others</div>
                            </Radio>
                        </Radio.Group>
                    </div>
                </Form.Item>
                <Form.Item<Profile>
                    name={'gender'}
                    rules={[
                        {
                            required: true,
                            message: 'Please Select a Gender',
                        },
                    ]}
                >
                    <div className="flex gap-4 items-center mt-16">
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={'m'}>
                                    <div>Male</div>
                                </Radio>
                                <Radio value={'f'}>
                                    <div>Female</div>
                                </Radio>
                                <Radio value={'o'}>
                                    <div>Others</div>
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </Form.Item>
                <Select
                    defaultValue={'Select something'}
                    options={items.map((item) => ({ label: item, value: item }))}
                    style={{ width: '300px' }}
                >
                    jewkej
                </Select>
                <Form.Item className="bottom-0 w-full fixed flex justify-center p-4 left-0">
                    <Button size="large" type="primary" htmlType="submit">
                        Contiue
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Components;
