import { useContext, useEffect, useState } from 'react';
import useQuery from '../../hooks/useQuery.hook';
import useEligibilitySvc from '../../services/eligibility.svc';
import Loader from '../../components/Loader';
import { EligibilityStatus } from '../../types/common/response.type';
import { AppContext } from '../../context/app';
import { Plan } from '../../types/plan.type';
import usePlanSvc from '../../services/plan.svc';
import { getDateInDayMonthYearFormat, getEMIPercentage, getRoundedValue } from '../../utils';
import { ZeroPercentEMITag } from '../../components/common/zero-percent-emi-tag';
import regexMap from '../../regex'

const Limits = () => {
    const [loading, setLoading] = useState(true);
    const [limit, setLimit] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [plansConfig, setPlansConfig] = useState<{ plans: Plan[]; loading: boolean; value: number; mode: string }>({
        plans: [],
        loading: false,
        value: 0,
        mode: 'show',
    });

    const { checkEligibility } = useEligibilitySvc();
    const { getEligibilityPlans } = usePlanSvc();
    const { handleEligibility, handleError } = useContext(AppContext);
    const {
        queryParams: { merchantName },
    } = useQuery();
    const { APPROVED } = EligibilityStatus;

    const { loading: plansLoading, plans, value, mode: plansMode } = plansConfig;

    const updatePlansConfig = (config: Partial<{ plans: Plan[]; loading: boolean; value: number; mode: string }>) => {
        setPlansConfig((pre) => ({
            ...pre,
            ...config,
        }));
    };

    const updatePlans = async (value: number) => {
        if (value > limit) {
            updatePlansConfig({
                plans: [],
                value,
            });
            return;
        }
        try {
            updatePlansConfig({ loading: true });
            const plans = await getEligibilityPlans(value);
            updatePlansConfig({
                loading: false,
                plans,
                value,
                mode: 'show',
            });
        } catch (error) {
            handleError(error);
            updatePlansConfig({
                loading: false,
                plans: [],
                mode: 'show',
            });
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const eligibility = await checkEligibility();
                if (eligibility.status === APPROVED) {
                    setLoading(false);
                    setLimit(eligibility.emiLimit as number);
                } else {
                    handleEligibility(eligibility);
                }
            } catch (error) {
                handleError(error);
            }
        })();
    }, []);

    useEffect(() => {
        if (limit > 0) {
            updatePlansConfig({ value: limit });
            updatePlans(limit);
        }
    }, [limit]);

    if (loading) {
        return <Loader />;
    }
    const isLimitExceeded = value > limit;
    return (
        <div className="relative">
            <img src="/images/celebrate.svg" className="w-[calc(100%+30px)] absolute -right-[15px] -top-[6px]" />
            <div className="rounded-[24px] bg-white border border-solid border-grayOutline p-[16px]">
                <div className="flex flex-col gap-[16px] items-center justify-center">
                    <img src="/images/limit-check.svg" className="w-[60px]" />
                    <div>
                        <div className="font-robotoBold text-18 text-titleDark text-center">You are approved</div>
                        <div className="font-robotoMedium text-14 text-lightBody text-center mt-[4px]">
                            for EMI purchases on {merchantName} upto
                        </div>
                    </div>
                    <div className="text-[45px] font-openSansBold text-titleDark text-center">
                        ₹ {limit?.toLocaleString('EN-IN')}
                        <p className="text-center text-10 font-robotoRegular text-lightBody max-w-[200px]">
                            Note: for purchase above ₹{limit?.toLocaleString('EN-IN')} pay extra amount to the
                            shopkeeper
                        </p>
                    </div>
                    <div className="p-[12px] rounded-lg border border-solid border-grayOutline bg-grayShade w-full mt-[14px]">
                        <p className="text-primaryGray text-14 font-robotoBold">See EMI Plans Below</p>
                        <div className="px-[12px] py-[8px] bg-white border border-solid border-grayOutline rounded-lg mt-[6px]">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-14 font-openSansBold text-primaryGray">
                                    Order Value : {plansMode === 'show' && `₹${value?.toLocaleString('EN-IN')}`}
                                </p>
                                {plansMode === 'show' ? (
                                    <p
                                        onClick={() => {
                                            updatePlansConfig({ mode: 'input' });
                                        }}
                                        className="cursor-pointer text-12 font-robotoBold text-hyperLink"
                                    >
                                        Change
                                    </p>
                                ) : (
                                    <div className="flex gap-[6px] items-center">
                                        <input
                                            value={inputValue}
                                            disabled={plansLoading}
                                            autoFocus
                                            className={`w-[70px] h-[25px] py-[4px] rounded-none border border-solid  ${
                                                isLimitExceeded
                                                    ? 'border-error focus:border-error'
                                                    : 'border-grayOutline focus:border-grayOutline'
                                            } focus:outline-none text-13 font-robotoRegular text-lightBody pl-[4px]`}
                                            onChange={(e) => {
                                                if (!e.target.value || regexMap.digits.test(e.target.value)) {
                                                    setInputValue(e.target.value);
                                                }
                                            }}
                                        />
                                        <div
                                            onClick={() => {
                                                if (plansLoading || !inputValue) {
                                                    return;
                                                }
                                                updatePlans(Number(inputValue));
                                            }}
                                            className="py-[3px] px-[8px] border border-solid rounded-[2px] border-mint cursor-pointer font-robotoRegular text-mint h-fit"
                                        >
                                            <p className="text-[10px]">Submit</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {plansLoading ? (
                        <div className="h-[200px] w-full flex justify-center items-center">
                            <Loader centered={false} />
                        </div>
                    ) : (
                        <>
                            {isLimitExceeded && (
                                <ul className="text-11 font-robotoMedium text-error">
                                    <li>Your EMI purchase limit is ₹{limit.toLocaleString('EN-IN')} only</li>
                                    <li>
                                        For purchase above ₹{limit.toLocaleString('EN-IN')} pay extra amount to the
                                        shopkeeper
                                    </li>
                                </ul>
                            )}
                            {plans.length > 0 && (
                                <div className="w-full flex flex-col px-[12px]">
                                    <div className="flex items-center gap-4 font-robotoBold">
                                        <img
                                            src="/icons/down-payment.svg"
                                            alt="down-payment"
                                            className="h-[24px] w-[24px]"
                                        />
                                        <p className="text-primaryGray text-14">
                                            Pay only <span className="text-contentColor">₹{plans[0]?.dp} now</span>
                                        </p>
                                    </div>
                                    <div className="font-robotoBold text-14 mt-[4px] text-contentColor">
                                        Choose EMI tenure
                                    </div>
                                    <div className="text-lightBody italic font-robotoRegular text-12 my-[6px]" dangerouslySetInnerHTML={{
                                        __html: `EMIs Starting ${getDateInDayMonthYearFormat(plans[0]?.startAt, 'upper')}`
                                    }} />
                                    <div className="flex flex-col gap-[10px] mt-[10px]">
                                        {plans.map(({ dp, emi, tenure, planId, isZeroPercent }, i) => {
                                            const roi = getEMIPercentage({
                                                dp,
                                                emi,
                                                orderValue: value,
                                                tenure,
                                            });
                                            return (
                                                <>
                                                    <div className="pl-4 w-full flex justify-between">
                                                        <p className="text-14 font-robotoBold text-titleDark">
                                                            ₹{getRoundedValue(emi)}x{' '}
                                                            <span className="text-lightBody !font-robotoMedium">
                                                                {tenure} months
                                                            </span>
                                                        </p>
                                                        <div>
                                                            {isZeroPercent ? (
                                                                <ZeroPercentEMITag />
                                                            ) : (
                                                                <span className="text-10 text-lightBody font-robotoMedium">
                                                                    ({roi}% per mon*)
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {i < plans.length - 1 && (
                                                        <div className="h-[1px] w-full bg-grayOutline" />
                                                    )}
                                                </>
                                            );
                                        })}
                                    </div>
                                    <i className="w-full mt-[6px] text-11 font-robotoRegular text-lightBody">
                                        *Total extra payment per month/order value
                                    </i>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="mt-[30px]">
                <div className='flex flex-col gap-[4px]'>
                    {['No Early payment charges', 'No hidden fees'].map((text) => (
                        <div className="flex gap-[8px]">
                            <img src="/images/checked-outline.svg" />
                            <p className="text-13 font-robotoRegular text-titleDark">{text}</p>
                        </div>
                    ))}
                </div>
                <div className="mt-[20px] flex flex-col justify-center items-center gap-[6px] pb-[20px]">
                    <p className="text-12 text-titleDark font-robotoRegular">Pay EMIs on the Snapmint App</p>
                    <div className="flex items-center relative gap-[20px]">
                        <a href="https://play.google.com/store/apps/details?id=com.snapmint.customerapp">
                            <img className="h-[45px] cursor-pointer" src="/images/google-play-badge.svg" />
                        </a>
                        <a href="https://apps.apple.com/us/app/snapmint-buy-now-pay-in-emis/id6450001111">
                            <img className="h-[45px] cursor-pointer" src="/images/apple-store-download.svg" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Limits;
