export const PlanString = ({ planString, onClick }: { planString: string; onClick?: () => void }) => {
    return (
        <div className="px-[4px] py-[1px] border !border-secondaryGreen border-solid w-max font-robotoRegular text-8 rounded-[2px] cursor-pointer flex items-center gap-2" onClick={onClick}>
            <p className="text-secondaryGreen font-robotoBold text-8">
                {planString}
            </p>
            <img src="/icons/edit.svg" alt="edit" />
        </div>
    );
};
