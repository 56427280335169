import useQuery from '../../hooks/useQuery.hook';

export default function Header() {
    const { queryParams } = useQuery();
    const routeHeaderMap: Record<string, string> = {
        '/login': 'Enter Mobile Number',
        '/otp': 'Verify Number',
        '/address':
            queryParams.addressType === 'new_address'
                ? 'Add Delivery Address'
                : queryParams.addressType === 'address_list'
                ? 'Choose Delivery Address'
                : '',
        '/profile': 'Personal Details',
        '/income_verify': 'Bank Details',
        '/plan': 'Choose Payment Plan',
        '/kyc': 'Complete KYC',
        '/auto-debit': 'Set Up EMI Auto Pay',
        '/dp': 'Select Payment Method',
        '/eligibility/profile': 'Personal Details',
        '/eligibility/limits': 'Credit Approved',
        '/order/status': 'Order Processing'
    };
    return (
        <div className='bg-header flex items-center min-[600px]:justify-center'>
            <p className='ml-[16px] text-primaryGray font-openSansSemiBold text-18 py-[12px]'>{routeHeaderMap[window.location.pathname] || <img src='/images/snapmint.svg' />}</p>
        </div>
    );
}
