import useQuery from '../hooks/useQuery.hook';
import Order from '../types/order.type';
import { convertObjectToQueryParams } from '../utils';
import restClient from '../utils/rest-client';

export default function useOrderSvc() {
    const { queryParams } = useQuery();
    const { id } = queryParams;
    const params = convertObjectToQueryParams({ id });

    async function getOrderStatus(): Promise<{
        status: string;
        message: string;
        action?: string;
        method?: string;
        merchantParams?: { [key: string]: string };
    }> {
        return restClient.get(`/orders/status?${params}`);
    }

    async function getOrderDetails(): Promise<Order> {
        return restClient.get(`/orders?${params}`);
    }

    function getFailureUrl(): Promise<{ action: string; method: string; merchantParams: Record<string, any> }> {
        return restClient.get(`/orders/failure-url?${params}`);
    }

    return {
        getOrderStatus,
        getOrderDetails,
        getFailureUrl,
    };
}
