import { Layout } from 'antd';
import Header from './Header';
import Content from './Content';
import PlanCard from '../PlanCard';
import { useContext } from 'react'
import { AppContext } from '../../context/app'
import EligibilityHeader from '../eligibility-header'

const { Content: AntContent } = Layout;

export default function Page({ children, className }: { children: JSX.Element | JSX.Element[]; className?: string }) {
    const { isEligibilityFlow } = useContext(AppContext);
    return (
        <Layout className="flex flex-col h-[100vh]">
            <Header />
            <div className="bg-gradient flex-1">
                <AntContent className="max-w-[450px] m-auto pb-2">
                    <Content className="px-[16px] pt-[16px]">
                        {isEligibilityFlow ? <EligibilityHeader /> : <PlanCard />}
                        <div className="mt-[16px]">{children}</div>
                    </Content>
                </AntContent>
            </div>
        </Layout>
    );
}
