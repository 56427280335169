import Address from '../../types/address.type';
import { Action } from '../../types/common/reducer.type';

export enum AddressAction {
    UPDATE_LOADING = 'UPDATE_LOADING',
    UPDATE_ADDRESS_LIST = 'UPDATE_ADDRESS_LIST',
}

export interface AddressState {
    loading: boolean;
    addressList: Address[];
}

export const initialAddressState: AddressState = {
    loading: true,
    addressList: [],
};

const { UPDATE_LOADING, UPDATE_ADDRESS_LIST } = AddressAction;

export const AddressReducer = (state: AddressState, action: Action<AddressState, AddressAction>): AddressState => {
    const { payload, type } = action;

    switch (type) {
        case UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading,
            } as AddressState;

        case UPDATE_ADDRESS_LIST:
            return {
                ...state,
                addressList: payload.addressList,
            } as AddressState;
        default:
            return state;
    }
};
