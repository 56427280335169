import { createContext, useContext, useEffect, useState } from 'react';
import { AppContext } from '../app';
import useQuery from '../../hooks/useQuery.hook';
import { useNavigate } from 'react-router-dom';
import useAuthSvc from '../../services/auth.svc';

interface OtpContextType {
    loading: boolean;
    otp: string;
    mobileNumber: string;
    resendOtpTimer: number;
    updateOtp: (otp: string) => void;
    submitOtp: () => void;
    sendOtp: () => void;
}

export const OtpContext = createContext<OtpContextType>({} as OtpContextType);

export const OtpStateProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(true);
    const [otp, setOtp] = useState('');
    const [resendOtpTimer, setResendOtpTimer] = useState(0);

    const {
        state: { cart },
        handleError,
        updateUserToken,
    } = useContext(AppContext);

    const {
        queryParamsString,
        queryParams: { mobile, firstName, lastName, redirectUrl, merchantId },
    } = useQuery();
    const navigate = useNavigate();
    const { sendOtp: sendUserOtp, login } = useAuthSvc();
    const mobileNumber = cart?.mobile ?? mobile;

    const submitOtp = async () => {
        try {
            setLoading(true);
            const data = await login({
                mobile: mobileNumber!,
                firstName,
                lastName,
                otp: otp,
                cartId: String(cart?.id),
                merchantId,
            });
            updateUserToken(data.tokenId);
            if (redirectUrl) {
                navigate(`${redirectUrl}?${queryParamsString}`, { replace: true });
            } else {
                navigate(`/?${queryParamsString}`, { replace: true });
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const sendOtp = async () => {
        try {
            setLoading(true);
            await sendUserOtp(mobileNumber);
            setResendOtpTimer(30);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
            setResendOtpTimer(30);
        }
    };

    useEffect(() => {
        if (mobileNumber) {
            sendOtp();
        } else {
            navigate(`/login?${queryParamsString}`)
        }
    }, [mobileNumber]);

    useEffect(() => {
        if (resendOtpTimer > 0) {
            setTimeout(() => {
                setResendOtpTimer((prev) => prev - 1);
            }, 1000);
        }
    }, [resendOtpTimer]);

    return (
        <OtpContext.Provider
            value={{
                loading,
                otp,
                mobileNumber,
                resendOtpTimer,
                updateOtp: (otp: string) => {
                    setOtp(otp);
                },
                submitOtp,
                sendOtp
            }}
        >
            {children}
        </OtpContext.Provider>
    );
};
